import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHeaders, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UploadFileService {

  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  upload(resourceUrl: string, file: File, marketplaceAccountId: any) {
    const formData: FormData = new FormData();

    formData.append('file', file);
    formData.append('marketplaceAccountId', marketplaceAccountId);

    const req = new HttpRequest('POST', `${this.baseUrl}/${resourceUrl}`, formData, {
      reportProgress: true,
      responseType: 'json'
    });
    //return this.http.request(req);
    return this.http.post<any>(`${this.baseUrl}/${resourceUrl}`, formData);
  }
}