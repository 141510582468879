import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { MarketplaceAccount } from '../_models/marketplace-account';

@Injectable()
export class MarketplaceAccountService {
    constructor(private http: HttpClient) { }

    async getByCompanyId(companyId: any){
        return await this.http.get<MarketplaceAccount[]>(environment.apiUrl + 'marketplacesource/accounts?company_id=' + companyId).toPromise();
    }

    async getByPlatformIdAndPlatform(platformId: any, platform: string) {
        return await this.http.get<MarketplaceAccount>(environment.apiUrl + 'marketplacesource?platform_id=' + platformId + "&platform=" + platform).toPromise();
    }

    async doAuthentication(code: string, userId: any, companyId: any) {
        return await this.http.post<any[]>(environment.apiUrl + 'marketplacesource/mloauth', {code: code, userId: userId, companyId: companyId}).toPromise();
    }

    async patch(id: any, enabled: any) {
        return await this.http.patch<any>(environment.apiUrl + "marketplacesource/" + id, {enabled: enabled}).toPromise();
    }

    async save(saleChannel: any){
        return await this.http.post<MarketplaceAccount>(environment.apiUrl + 'marketplacesource', saleChannel).toPromise();
    }
    async update(saleChannel: any) {
        return await this.http.put<any[]>(environment.apiUrl + 'marketplacesource/' + saleChannel.id, saleChannel).toPromise();
    }
    async getByPlatform(filter: any){
      return await this.http.post<any[]>(environment.apiUrl + 'marketplacesource/filter', filter).toPromise();
  }
}
