import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { ForgotPasswordComponent } from './views/login/forgotpassword.component';
import { ChangePasswordComponent } from './views/login/changepassword.component';
import { RegisterComponent } from './views/register/register.component';
import { AuthGuard, RouteGuard } from './_guards/index';
import { FullLayoutComponent } from './containers/full-layout/full-layout.component';
import { Login2FAComponent } from './views/login/two-factor-auth.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    },
    canActivate: [AuthGuard],
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'forgotpassword',
    component: ForgotPasswordComponent,
    data: {
      title: 'Forgot Password Page'
    }
  },
  {
    path: 'changepassword',
    component: ChangePasswordComponent,
    data: {
      title: 'Change Password Page'
    }
  },
  {
    path: 'two-factor-auth',
    component: Login2FAComponent,
    data: {
      title: 'Two Factor Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Início'
    },
    children: [
      {
        path: 'base',
        loadChildren: () => import('./views/base/base.module').then(m => m.BaseModule)
      },
      {
        path: 'buttons',
        loadChildren: () => import('./views/buttons/buttons.module').then(m => m.ButtonsModule)
      },
      {
        path: 'charts',
        loadChildren: () => import('./views/chartjs/chartjs.module').then(m => m.ChartJSModule)
      },
      {
        path: 'home',
        loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'icons',
        loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule),
      },
      {
        path: 'notifications_',
        loadChildren: () => import('./views/notifications_/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'theme',
        loadChildren: () => import('./views/theme/theme.module').then(m => m.ThemeModule)
      },
      {
        path: 'widgets',
        loadChildren: () => import('./views/widgets/widgets.module').then(m => m.WidgetsModule)
      },
      {
        data: {
          title: 'Vendas'
        },
        path: '',
        children: [
          {
            path: 'devolution',
            loadChildren: () => import('./views/devolution/devolution.module').then(m => m.DevolutionModule ),
            canActivate: [RouteGuard],
          },
          {
            path: 'orderoperational',
            loadChildren: () => import('./views/orderoperational/orderoperational.module').then(m => m.OrderOperationalModule),
            canActivate: [RouteGuard],
          },
          {
            path: 'order-menu',
            loadChildren: () => import('./views/order-menu/order-menu.module').then(m => m.OrderMenuModule),
            canActivate: [RouteGuard],
          },
          {
            path: 'order',
            loadChildren: () => import('./views/order/order.module').then(m => m.OrderModule),
            canActivate: [RouteGuard],
          },
          {
            path: 'orderdetail/:id',
            loadChildren: () => import('./views/orderdetail/order.detail.module').then(m => m.OrderDetailModule),
            canActivate: [RouteGuard],
          },
          {
            path: 'invoices',
            loadChildren: () => import('./views/invoices/invoices.module').then(m => m.InvoicesModule),
            canActivate: [RouteGuard],
          }
        ]
      },
      {
        data: {
          title: 'Controle'
        },
        path: '',
        children: [
          {
            path: 'pendencies',
            loadChildren: () => import('./views/pendency/pendency.module').then(m => m.PendencyModule),
            canActivate: [RouteGuard],
          },
          {
            path: 'stockmovement',
            loadChildren: () => import('./views/stockmovement/stockmovement.module').then(m => m.StockMovementModule),
            canActivate: [RouteGuard],
          },
          {
            path: 'stocks',
            loadChildren: () => import('./views/stock/stock.module').then(m => m.StockModule),
            canActivate: [RouteGuard],
          },
          {
            path: 'stocks/add',
            loadChildren: () => import('./views/stock/stocks-form/stocks-form.module').then(m => m.StocksFormModule),
            canActivate: [RouteGuard],
          },
          {
            path: 'stocks/edit/:id',
            loadChildren: () => import('./views/stock/stocks-form/stocks-form.module').then(m => m.StocksFormModule)
          },
          {
            data: {
              title: 'Transferência de estoque'
            },
            path: 'stockmovement/transfer',
            loadChildren: () => import('./views/stockmovement/stocks-transfer/stocks-transfer.module').then(m => m.StocksTransferModule),
            canActivate: [RouteGuard],
          },
        ]
      },
      {
        data: {
          title: 'Compras'
        },
        path: '',
        children: [
          {
            path: 'purchases',
            loadChildren: () => import('./views/purchase/purchase.module').then(m => m.PurchaseModule),
            canActivate: [RouteGuard],
          },
          {
            path: 'sales',
            loadChildren: () => import('./views/sale/sale.module').then(m => m.SaleModule),
            canActivate: [RouteGuard],
          },
          {
            path: 'purchases/add',
            loadChildren: () => import('./views/purchase/form-purchase/form-purchase.module').then(m => m.FormPurchaseModule),
            canActivate: [RouteGuard],
          },
          {
            path: 'purchaseorders/list',
            loadChildren: () => import('./views/purchase-orders/purchase-orders.module').then(m => m.PurchaseOrdersModule),
            canActivate: [RouteGuard],
          },
          {
            path: 'purchaseorders/add',
            loadChildren: () => import('./views/purchase-orders/form-purchase-orders/form-purchase-orders.module').then(m => m.FormPurchaseOrdersModule),
            canActivate: [RouteGuard],
          },
          {
            path: 'purchaseorders/edit/:id',
            loadChildren: () => import('./views/purchase-orders/form-purchase-orders/form-purchase-orders.module').then(m => m.FormPurchaseOrdersModule),
            canActivate: [RouteGuard],
          },
        ]
      },
      {
        data: {
          title: 'Financeiro'
        },
        path: '',
        children: [
          {
            path: 'billstopay',
            loadChildren: () => import('./views/billstopay/billstopay.module').then(m => m.BillsToPayModule),
            canActivate: [RouteGuard],
          },
          {
            path: 'billstoreceive',
            loadChildren: () => import('./views/billstoreceive/billstoreceive.module').then(m => m.BillsToReceiveModule),
            canActivate: [RouteGuard],
          },
          {
            path: 'extract',
            loadChildren: () => import('./views/extract/extract.module').then(m => m.ExtractModule),
            canActivate: [RouteGuard],
          },
          {
            path: 'extract/import',
            loadChildren: () => import('./views/extract/import/extract-import.module').then(m => m.ExtractImportModule),
            canActivate: [RouteGuard],
          },
          {
            path: 'movementbytypereport/:id',
            loadChildren: () => import('./views/movementbytypereport/movementbytypereport.module').then(m => m.MovementByTypeReportModule),
            canActivate: [RouteGuard],
          },
          {
            path: 'movementsbytype',
            loadChildren: () => import('./views/movementbytype/movementbytype.module').then(m => m.MovementByTypeModule),
            canActivate: [RouteGuard],
          },
        ]
      },
      {
        data: {
          title: 'Cadastro'
        },
        path: '',
        children: [
          {
            path: 'categories',
            loadChildren: () => import('./views/category/category.module').then(m => m.CategoryModule),
            canActivate: [RouteGuard],
          },
          {
            path: 'products',
            loadChildren: () => import('./views/product/product.module').then(m => m.ProductModule),
            canActivate: [RouteGuard],
          },
          {
            path: 'suppliers',
            loadChildren: () => import('./views/supplier/supplier.module').then(m => m.SupplierModule),
            canActivate: [RouteGuard],
          },
          {
            path: 'customers',
            loadChildren: () => import('./views/customer/customer.module').then(m => m.CustomerModule),
            canActivate: [RouteGuard],
          },
          {
            path: 'announcement',
            loadChildren: () => import('./views/announcement/announcement.module').then(m => m.AnnouncementModule),
            canActivate: [RouteGuard],
          },
          {
            path: 'announcementedit',
            loadChildren: () => import('./views/announcementedit/announcement-edit.module').then(m => m.AnnouncementEditModule),
            canActivate: [RouteGuard],
          },
          {
            path: 'announcementclone',
            loadChildren: () => import('./views/announcementclone/announcement-clone.module').then(m => m.AnnouncementCloneModule),
            canActivate: [RouteGuard],
          },
          {
            path: 'question',
            loadChildren: () => import('./views/question/question.module').then(m => m.QuestionModule ),
            canActivate: [RouteGuard],
          },
          {
            path: 'message',
            loadChildren: () => import('./views/message/message.module').then(m => m.MessageModule ),
            canActivate: [RouteGuard],
          },
          {
            path: 'automaticmessage',
            loadChildren: () => import('./views/automaticmessage/automaticmessage.module').then(m => m.AutomaticMessageModule ),
            canActivate: [RouteGuard],
          },
          {
            path: 'salesresult',
            loadChildren: () => import('./views/salesresult/salesresult.module').then(m => m.SalesResultModule),
            canActivate: [RouteGuard],
          },
          {
            path: 'bankaccount',
            loadChildren: () => import('./views/bankaccount/bankaccount.module').then(m => m.BankAccountModule),
            canActivate: [RouteGuard],
          },
          {
            path: 'paymentmethod',
            loadChildren: () => import('./views/paymentmethod/paymentmethod.module').then(m => m.PaymentMethodModule),
            canActivate: [RouteGuard],
          },
          {
            path: 'movementtypes',
            loadChildren: () => import('./views/movementtype/movement-type.module').then(m => m.MovementTypeModule),
            canActivate: [RouteGuard],
          },
          {
            path: 'movementtypegroups',
            loadChildren: () => import('./views/movementtypegroup/movement-type-group.module').then(m => m.MovementTypeGroupModule),
            canActivate: [RouteGuard],
          },
          {
            path: 'account',
            loadChildren: () => import('./views/account/account.module').then(m => m.AccountModule),
            canActivate: [RouteGuard],
          },
          {
            path: 'quick-answer',
            loadChildren: () => import('./views/quick-answer/quick-answer.module').then(m => m.QuickAnswerModule),
            canActivate: [RouteGuard],
          },
          {
            path: 'bankdraftholders/list',
            loadChildren: () => import('./views/bank-draft-holder/bank-draft-holder-list.module').then(m => m.BankDraftHolderListModule)
          },
          {
            path: 'bankdraftholders/add',
            loadChildren: () => import('./views/bank-draft-holder/bank-draft-holder-form/bank-draft-holder-form.module').then(m => m.BankDraftHolderFormModule)
          },
          {
            path: 'bankdraftholders/edit/:id',
            loadChildren: () => import('./views/bank-draft-holder/bank-draft-holder-form/bank-draft-holder-form.module').then(m => m.BankDraftHolderFormModule)
          },
          {
            path: 'bankdrafts/list',
            loadChildren: () => import('./views/bank-draft/bank-draft-list.module').then(m => m.BankDraftListModule)
          },
          {
            path: 'bankdrafts/add',
            loadChildren: () => import('./views/bank-draft/bank-draft-form/bank-draft-form.module').then(m => m.BankDraftFormModule)
          },
          {
            path: 'bankdrafts/edit/:id',
            loadChildren: () => import('./views/bank-draft/bank-draft-form/bank-draft-form.module').then(m => m.BankDraftFormModule)
          },
          {
            path: 'stock',
            loadChildren: () => import('./views/stock/stock.module').then(m => m.StockModule)
          }
        ]
      },
      /*
      {
        path: 'communication',
        loadChildren: () => import('./views/communication/communication.module').then(m => m.CommunicationModule)
      },
      */
      {
        data: {
          title: 'Configurações'
        },
        path: '',
        children: [
          {
            path: 'saleschannels/:saleChannel',
            loadChildren: () => import('./views/marketplaceaccount/marketplaceaccount.module').then(m => m.MarketplaceAccountModule)
          },
          {
            path: 'profile',
            loadChildren: () => import('./views/profile/profile.module').then(m => m.ProfileModule)
          },
          {
            path: 'digitalcertificates',
            loadChildren: () => import('./views/digitalcertificates/digitalcertificates.module').then(m => m.DigitalcertificatesModule)
          },
          {
            path: 'company-preferences',
            loadChildren: () => import('./views/company-preference/company-preference.module').then(m => m.CompanyPreferencesModule)
          },
          {
            path: 'users',
            loadChildren: () => import('./views/user/user.module').then(m => m.UserModule)
          },
          {
            path: 'users-groups',
            loadChildren: () => import('./views/users-group/users-group.module').then(m => m.UsersGroupModule)
          },
          {
            path: 'printers',
            loadChildren: () => import('./views/printers/printer.module').then(m => m.PrinterModule)
          }
        ]
      },
      {
        path: '',
        data: {
          title: ''
        },
        children: [
          {
            path: 'denied',
            loadChildren: () => import('./views/denied/denied.module').then(m => m.DeniedModule)
          }

        ]
      }
    ],

  },

  {
    path: '',
    component: FullLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'communication',
        loadChildren: () => import('./views/communication/communication.module').then(m => m.CommunicationModule)
      }
    ]
  },
  { path: '**', component: P404Component },
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  providers: [AuthGuard, RouteGuard],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
