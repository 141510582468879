import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from './../../_services/index';
import { UserService } from './../../_services/index';
import { AuthService } from './../../_guards/auth.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-two-factor-auth',
  templateUrl: './two-factor-auth.component.html',
  styleUrls: ['./two-factor-auth.component.scss']
})
export class Login2FAComponent implements OnInit {
  authForm: FormGroup;
  loading = false;
  submitted = false;
  error = '';
  returnUrl: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private authService: AuthService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.authForm = this.formBuilder.group({
        code1: ['', [Validators.required, Validators.pattern(/^\d$/)]],
        code2: ['', [Validators.required, Validators.pattern(/^\d$/)]],
        code3: ['', [Validators.required, Validators.pattern(/^\d$/)]],
        code4: ['', [Validators.required, Validators.pattern(/^\d$/)]],
        code5: ['', [Validators.required, Validators.pattern(/^\d$/)]],
        code6: ['', [Validators.required, Validators.pattern(/^\d$/)]]
    });

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  goBack() {
    this.router.navigate(['/login']);
  }

  onSubmit() {
    this.submitted = true;

    if (this.authForm.invalid) {
      return;
    }

    const code = `${this.authForm.controls.code1.value}${this.authForm.controls.code2.value}${this.authForm.controls.code3.value}${this.authForm.controls.code4.value}${this.authForm.controls.code5.value}${this.authForm.controls.code6.value}`;
    const user = this.authService.getLoggedUser();

    this.loading = true;
    this.userService.verifyTwoFactorCode(user, code)
      .subscribe(
        data => {
          this.router.navigate([this.returnUrl]);
        },
        error => {
          this.error = error;
          this.loading = false;
          this.toastr.error("Código de autenticação inválido!");
        }
      );
  }

  moveFocus(event: Event, nextFieldId: string | null) {
      const input = event.target as HTMLInputElement;
      if (input.value.length === 1 && nextFieldId) {
        const nextInput = document.getElementById(nextFieldId) as HTMLInputElement;
        if (nextInput) {
          nextInput.focus();
        }
      }
  }
}
